<template>
  <div class="history_container container_box_shadow">
    <div class="inner_wrapper">
      <div class="epermite_head">
        <h2 class="title">{{ $t("e_permit_tab.permit_history") }}</h2>
        <!-- <span
          class="createEPermit"
          @click="this.$props.changeTab(this.$props.tabs.ePermitCreate)"
        >
          {{ $t("e_permit_tab.permit_add") }}
        </span> -->
        <span class="createEPermit">
          {{ $t("e_permit_tab.permit_add") }}
        </span>
      </div>
      <div class="table_block">
        <div class="header_row flex-row">
          <span class="w20">{{ $t("e_permit_tab.to_country") }}</span>
          <span class="w20">{{ $t("e_permit_tab.departure_date") }}</span>
          <span class="w20">{{ $t("e_permit_tab.return_date") }}</span>
          <span class="w10">{{ $t("e_permit_tab.status") }}</span>
          <span class="w20"></span>
        </div>

        <div
          class="content_row flex-row"
          v-for="el in this.permits"
          :key="el.id"
        >
          <span class="w20">{{ el.country }}</span>
          <span class="w20">{{ el.departureDate }}</span>
          <span class="w20">{{ el.returnDate }}</span>
          <span class="w10">
            {{ $t(`e_permit_tab.statusType.${el.status}`) }}
          </span>

          <span class="w20">
            <div v-if="el.to_pay" class="mini__butt" @click="paymentReq(el.id)">
              {{ $t("e_permit_tab.payment") }}
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
  <PopupWindow
    v-if="this.$store.state.popupShow"
    :title="popupText.current.title"
    :message="popupText.current.message"
    :buttonText="popupText.current.buttonText"
  />
</template>

<script>
import PopupWindow from "@/components/online_application/PopupWindow.vue";

export default {
  components: { PopupWindow },

  props: {
    changeTab: "",
    tabs: {},
  },

  data() {
    return {
      permits: [],

      popupText: {
        success: {
          title: "PAYMENT WAS SUCCESSFUL",
          message: "УСПЕШНО",
          buttonText: "OK",
        },
        failure: {
          title: "PAYMENT FAILED",
          message: "НЕ УСПЕШНО",
          buttonText: "OK",
        },
        current: {
          title: "",
          message: "",
          buttonText: "",
        },
      },
    };
  },

  mounted() {
    this.$store.state.preLoader = true;

    window.axios
      .get(`${this.$store.state.backendRoutes.ePermits}`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.token}`,
        },
      })
      .then((res) => {
        this.permits = res.data.data.permits;

        if (
          res.data.data.payment_status != null &&
          res.data.data.payment_status != undefined
        ) {
          if (res.data.data.payment_status) {
            this.popupText.current = this.popupText.success;
          } else {
            this.popupText.current = this.popupText.failure;
          }

          this.$store.state.popupShow = true;
        }
      })
      .catch(this.$store.state.axiosCatch)
      .finally(() => {
        this.$store.state.preLoader = false;
      });
  },

  methods: {
    paymentReq(id) {
      this.$store.state.preLoader = true;

      axios
        .post(
          this.$store.state.backendRoutes.ePermitPayment,
          { permit_id: id },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.token}`,
            },
          }
        )
        .then((res) => {
          window.location.href = res.data;

          // window.open(res.data, "_blank");
        })
        .catch(this.$store.state.axiosCatch)
        .finally(() => {
          this.$store.state.preLoader = false;
        });
    },
  },
};
</script>
